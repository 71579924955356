<template>
  <div class="htd-attachment-index">
    <div class="tender-wrapper" v-for="tender of tenders" :key="tender.tenderGuid">
      <div class="tender-title">
        {{ tender.tenderName }}
      </div>
      <div class="tender-content">
        <div @click="handleClick(tender, 'masterPlan')" class="button">
          <i class="iconfont iconfolder1"></i>
          <span>总体计划</span>
        </div>
        <div @click="handleClick(tender, 'yearPlan')" class="button">
          <i class="iconfont iconfolder1"></i>
          <span>年计划</span>
        </div>
        <div @click="handleClick(tender, 'monthPlan')" class="button">
          <i class="iconfont iconfolder1"></i>
          <span>月计划</span>
        </div>
        <div @click="handleClick(tender, 'monthStat')" class="button">
          <i class="iconfont iconfolder1"></i>
          <span>月统计</span>
        </div>
        <div @click="handleClick(tender, 'yearStat')" class="button">
          <i class="iconfont iconfolder1"></i>
          <span>年统计</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tenderService from '@/services/tenderService'
import auth from '@/common/auth'
import { mapMutations } from 'vuex'

export default {
  name: 'HtdAttachmentIndex',
  data () {
    return {
      tenders: []
    }
  },
  methods: {
    handleClick (tender, flag) {
      console.log(tender)
      this.setTenderGuid(tender.tenderGuid)
      this.setExaminationType(flag)
      this.$router.push(`/htdYearPlan`)
    },
    async getTenders () {
      const response = await tenderService.listContractTenderByUser(auth.getUserInfo().userGuid)
      if (response.data.code === 1) {
        this.tenders = response.data.data
      }
    },
    ...mapMutations('planStatExamination', {
      setPlanStatExaminationGuid: 'setPlanStatExaminationGuid',
      setTenderGuid: 'setTenderGuid',
      setTenderName: 'setTenderName',
      setYear: 'setYear',
      setMonth: 'setMonth',
      setDate: 'setDate',
      setExaminationCode: 'setExaminationCode',
      setExaminationType: 'setExaminationType',
      setApprovalUserGuid: 'setApprovalUserGuid'
    })
  },
  async created () {
    await this.getTenders()
  }
}
</script>

<style scoped lang="scss">
.htd-attachment-index {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.tender-wrapper {
  width: 750px;
  height: 150px;
  display: flex;
  border: 1px solid #2aabd2;
  background-color: rgba(#2aabd2, .1);
  flex-direction: column;
  padding: 10px;
  margin-top: 30px;
  border-radius: 20px;
  box-shadow: 0 3px 15px #2aabd2;
  align-items: center;
  .tender-title {
    height: 40px;
  }
  .tender-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .button {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      i {
        display: block;
        color: rgba(255, 234, 147, .9);
        font-size: 80px;
        line-height: 80px;
        width: 80px;
        height: 80px;
      }
    }
  }
}
</style>
