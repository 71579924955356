<template>
  <div class="year-plan-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item v-show="showYear" label="年份：" prop="year">
          <el-input-number
            v-model="year"
            :step="1"
            :min="2018"
            :precision="0"
            step-strictly>
          </el-input-number>
        </el-form-item>
        <el-form-item v-show="showMonth" label="月份：" prop="month">
          <el-input-number
            v-model="month"
            :step="1"
            :min="1"
            :max="12"
            :precision="0"
            step-strictly>
          </el-input-number>
        </el-form-item>
        <el-form-item v-show="showDate" label="日期：" prop="date">
          <el-input-number
            v-model="date"
            :step="1"
            :min="1"
            :max="31"
            :precision="0"
            step-strictly>
          </el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div
      v-for="planStatExaminationGroup in planStatExaminationGroups"
      :key="planStatExaminationGroup.planStatExaminationGroupGuid"
      class="group-wrapper">
      <div class="year-title">
        {{ titleFilter(planStatExaminationGroup) }}
      </div>
      <div class="control">
        <el-link class="transfer mr-2" @click="handleAttachmentClick(planStatExaminationGroup)">附件</el-link>
        <el-link v-if="couldTransfer(planStatExaminationGroup)" class="mr-2 transfer"
                 @click="handleSubmitClick(planStatExaminationGroup)">传送
        </el-link>
        <el-link v-else class="text-muted mr-2">传送</el-link>
        <el-link v-if="finalSubmitShow" class="transfer mr-2" @click="handleFinalSubmitClick(planStatExaminationGroup)">
          最终批复
        </el-link>
        <el-link v-if="cancelFinalSubmitShow" class="transfer"
                 @click="handleCancelFinalSubmitClick(planStatExaminationGroup)">取批
        </el-link>
        <el-link class="transfer ml-2">微信推送</el-link>
        <el-link v-if="couldTransfer(planStatExaminationGroup) || couldDelete" class="transfer ml-2"
                 @click="handleDelete(planStatExaminationGroup)">删除
        </el-link>
      </div>
      <div
        v-for="(planStatExamination, index) in planStatExaminationGroup.planStatExaminations"
        :key="index"
        class="report-wrapper">
        <div class="report-item">
          <div class="bg-info" @click="handleClick(planStatExamination)">
            <i class="iconfont iconG"></i>
          </div>
          <div class="buttons-wrapper">
            <span style="font-size: 14px; padding-bottom: 2px;" @click="handleClick(planStatExamination)">
              {{ planStatExamination.examinationType }} {{ planStatExamination.report.reportName }}
            </span>
            <div class="icons btn-group btn-group-sm">
              <button class="btn" @click="handleReportClick(planStatExamination, 'pdf', true)">
                <i class="fas fa-file-pdf"></i>
              </button>
              <button class="btn" @click="handleReportClick(planStatExamination, 'pdf')">
                <i class="fas fa-file-pdf"></i>
              </button>
              <button class="btn" @click="handleReportClick(planStatExamination, 'docx')">
                <i class="fas fa-file-word"></i>
              </button>
              <button class="btn" @click="handleReportClick(planStatExamination, 'xlsx')">
                <i class="fas fa-file-excel"></i>
              </button>
              <button class="btn" @click="handleReportClick(planStatExamination, 'png')">
                <i class="fas fa-file"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <my-dialog
      ref="submitFormDialog"
      title="传送"
      size="md"
      ok-text="确定"
      cancel-text="取消"
      @ok-click="doSubmit"
    >
      <el-form class="text-center">
        <el-form-item>
          <el-cascader
            v-if="identityAndUsers.length"
            ref="myCascader"
            :props="{ children: 'users' }"
            :options="identityAndUsers"
            clearable>
          </el-cascader>
        </el-form-item>
      </el-form>
    </my-dialog>

    <my-dialog
      ref="myDialog"
      :title="dialogTitle"
      :hide-ok="true"
      cancel-text="关闭"
      body-height="700px"
      size="xl">
      <iframe ref="myIFrame" :src="url" width="100%" height="100%" scrolling="no" frameborder="0"></iframe>
    </my-dialog>

    <my-dialog
      ref="importDialog"
      title="导入"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="doImport"
    >
      <el-upload
        class="upload-control"
        ref="upload"
        :action="uploadAction"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :limit="1"
        :accept="accept"
        :data="uploadData"
        :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <div slot="tip" class="el-upload__tip">只能上传{{ tip }}文件，且不超过10MB</div>
      </el-upload>
    </my-dialog>

    <my-dialog
      ref="attachmentDialog"
      size="xl"
      title="附件列表"
      cancel-text="关闭"
      :hide-ok="true"
    >
      <el-form inline>
        <el-form-item>
          <el-upload
            class="upload-attachment"
            ref="attachmentUpload"
            :action="attachmentUploadAction"
            :on-remove="handleAttachmentRemove"
            :on-success="handleAttachmentSuccess"
            :on-error="handleAttachmentError"
            :show-file-list="true"
            :file-list="attachmentFileList"
            :limit="1"
            :data="attachmentUploadData"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取附件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleBeforeUpload">上传</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="attachmentList"
        border
        header-cell-class-name="bg-info text-light"
      >
        <el-table-column align="center" prop="OriginalFileName" header-align="center" label="文件名"></el-table-column>
        <el-table-column align="center" prop="FilesType" header-align="center" label="文件类型"></el-table-column>
        <el-table-column align="center" prop="FileSize" header-align="center" label="文件大小"></el-table-column>
        <el-table-column align="center" :formatter="timeFormatter" prop="CreateTime" header-align="center"
                         label="上传时间"></el-table-column>
        <el-table-column header-align="center" align="center" width="100" label="操作">
          <template slot-scope="scope">
            <a :href="reportPrefix + scope.row.SavePath" target="_blank" :download="scope.row.OriginalFileName"
               class="mr-2">下载</a>
            <a href="#" @click="handleAttachmentDeleteClick(scope.row)">删除</a>
          </template>
        </el-table-column>
      </el-table>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import moment from 'moment'
import PlanStatExaminationGroupModel from '@/model/PlanStatExaminationGroupModel'
import planStatExaminationGroupService from '@/services/planStatExaminationGroupService'
import auth from '@/common/auth'
import config from '@/config'
import { mapMutations, mapGetters } from 'vuex'
import MyDialog from '@/components/MyDialog/MyDialog'
import utility from '@/common/utility'
import axios from 'axios'
import PageOperation from '@/mixins/PageOperation'

export default {
  name: 'YearPlanIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar
  },
  data () {
    return {
      url: '',
      year: moment().year(),
      month: moment().month() + 1,
      date: moment().date(),
      planStatExaminationGroups: [],
      planStatExaminationModel: {},
      displayToYear: [
        'yearPlan',
        'yearStat'
      ],
      displayToMonth: [
        'monthPlan',
        'monthStat'
      ],
      displayToDate: [
        'dateStat'
      ],
      identityAndUsers: [],
      dialogTitle: '',
      descReports: [
        'GSM01',
        'GSM02',
        'GSM03',
        'GSM04',
        'GSM05',
        'GSM06'
      ],
      importReports: [
        'G03',
        'G15',
        'G20'
      ],
      coverReports: [
        'PG01',
        'PG02',
        'PG03',
        'PG04',
        'PG05',
        'GJ01',
        'GJ02',
        'G05',
        'G14',
        'G16',
        'G17'
      ],
      badaReports: [
        'G02',
        'G13'
      ],
      badaProjects: [
        'cee67bb0-1d3f-11ed-aee8-cd04bbdc0999',
        '055d33a0-1d40-11ed-aee8-cd04bbdc0999',
        '341669f0-1d40-11ed-aee8-cd04bbdc0999',
        '56dba0e0-1d40-11ed-aee8-cd04bbdc0999',
        '780d1870-1d40-11ed-aee8-cd04bbdc0999',
        '0edd1e60-2e5a-11ed-826a-8f383c4e29da'
      ],
      uploadData: {},
      uploadAction: `${config.reportHost}/UploadFile`,
      examinationModel: null,
      planStatExaminationGroup: null,
      attachmentUploadData: {},
      attachmentFileList: [],
      attachmentUploadAction: `${config.reportHost}/UploadFile`,
      attachmentList: [],
      accept: '.xls,.xlsx',
      tip: 'Excel'
    }
  },
  filters: {},
  computed: {
    searchBarShow () {
      let identityGuid = auth.getUserInfo().identityGuid
      return config.applyIdentities.indexOf(identityGuid) !== -1
    },
    finalSubmitShow () {
      let identityGuid = auth.getUserInfo().identityGuid
      return config.finalSubmitIdentities.indexOf(identityGuid) !== -1
    },
    cancelFinalSubmitShow () {
      if (config.proIdentities.indexOf(auth.getUserInfo().identityGuid) !== -1) {
        return true
      }
      return false
    },
    couldDelete () {
      if (config.proIdentities.indexOf(auth.getUserInfo().identityGuid) !== -1) {
        return true
      }
      return false
    },
    showYear () {
      return this.displayToYear.indexOf(this.examinationType) > -1 || this.displayToMonth.indexOf(this.examinationType) > -1 ||
        this.displayToDate.indexOf(this.examinationType) > -1
    },
    showMonth () {
      return this.displayToMonth.indexOf(this.examinationType) > -1 || this.displayToDate.indexOf(this.examinationType) > -1
    },
    showDate () {
      return this.displayToDate.indexOf(this.examinationType) > -1
    },
    reportPrefix () {
      return config.reportHost + '/' + config.reportFolder
    },
    ...mapGetters('planStatExamination', {
      tenderGuid: 'getTenderGuid',
      examinationType: 'getExaminationType'
    })
  },
  methods: {
    handleDelete (planStatExaminationGroup) {
      this.$confirm('确定要删除吗？').then(() => {
        planStatExaminationGroupService.deleteGroup(planStatExaminationGroup.planStatExaminationGroupGuid).then(res => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
            this.getData()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败，请检查是否有申报明细数据，若仍不可删除请联系管理员！'
            })
          }
        }).catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '删除失败，请联系管理员！'
          })
        })
      }).catch(() => {
        this.$message.info('已取消操作')
      })
    },
    handleAttachmentClick (planStatExaminationGroup) {
      this.planStatExaminationGroup = planStatExaminationGroup
      this.attachmentList = []

      this.$refs.attachmentDialog.open()
      this.getAttachmentList()
    },
    handleBeforeUpload () {
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.planStatExaminationGroup.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.attachmentUploadAction = `${config.reportHost}/UploadFile?date=${utility.getCurrentTime(8)}&isanalysi=0&examination=${this.planStatExaminationGroup.planStatExaminationGroupGuid}&userguid=${auth.getUserInfo().userGuid}&timestamp=${timestamp}&parameter=${token}&Category=0`

      this.attachmentUploadData = {
        date: utility.getCurrentTime(8),
        isanalysi: 0,
        examination: this.planStatExaminationGroup.planStatExaminationGroupGuid,
        userguid: auth.getUserInfo().userguid,
        timestamp: timestamp,
        parameter: token
      }

      this.$nextTick(() => {
        this.$refs.attachmentUpload.submit()
      })

      return false
    },
    handleAttachmentRemove () {
    },
    handleAttachmentSuccess () {
      this.$nextTick(() => {
        this.attachmentFileList = []
      })
      this.getAttachmentList()

      this.$message({
        type: 'success',
        message: '上传成功！'
      })
    },
    handleAttachmentError () {
      this.$message({
        type: 'error',
        message: '上传失败！'
      })
    },
    handleAttachmentDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let timestamp = utility.getCurrentTimestamp()
          let token = utility.getMd5(`${auth.getUserInfo().tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

          axios.post(`${config.reportHost}/DeleteFile?timestamp=${timestamp}&parameter=${token}&AttachmentGuid=${row.AttachmentGuid}&userguid=${auth.getUserInfo().userGuid}`)
            .then(res => {
              if (res.data.Code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.Msg
                })
              }
              this.getAttachmentList()
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败，请重试！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    getAttachmentList () {
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.planStatExaminationGroup.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.attachmentList = []
      axios.get(`${config.reportHost}/GetFileList`, {
        params: {
          examination: this.planStatExaminationGroup.planStatExaminationGroupGuid,
          userguid: this.planStatExaminationGroup.createUserGuid,
          timestamp: timestamp,
          parameter: token
        }
      }).then(res => {
        if (res.data.Code === 1) {
          this.attachmentList = res.data.Msg.map(item => {
            item.FileSize = utility.floatDivide(item.FileSize, 1024).toFixed(2) + ' KB'
            return item
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.Msg
          })
        }
      }).catch(err => {
        console.log(err)
        this.$message({
          type: 'error',
          message: '获取附件失败，请重试！'
        })
      })
    },
    couldTransfer (examinationGroupModel) {
      return auth.getUserInfo().userGuid === examinationGroupModel.approvalUserGuid
    },
    handleSubmitClick (row) {
      this.planStatExaminationGroupModel = row
      this.getNextUsers(row.planStatExaminationGroupGuid)
    },
    doSubmit () {
      let nodes = this.$refs.myCascader.getCheckedNodes()
      if (!nodes.length) {
        this.$message({
          type: 'warning',
          message: '请选择传送对象！'
        })
        return
      }
      let userModel = nodes[0].data
      let flowModel = nodes[0].parent.data

      planStatExaminationGroupService.transfer(userModel.userGuid, flowModel.stepName, flowModel.flowGuid, this.planStatExaminationGroupModel.planStatExaminationGroupGuid, flowModel.sortId)
        .then(res => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '传送成功！'
            })
            this.getData()
            this.$refs.submitFormDialog.close()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '传送失败！'
          })
        })
    },
    handleFinalSubmitClick (row) {
      this.$confirm('确定要最终批复吗？')
        .then(() => {
          this.planStatExaminationGroupModel = row
          planStatExaminationGroupService.finalSubmit(this.planStatExaminationGroupModel.planStatExaminationGroupGuid)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '最终批复成功！'
                })
                this.getData()
                this.$refs.submitFormDialog.close()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '最终批复失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleCancelFinalSubmitClick (row) {
      this.$confirm('确定要取消最终批复吗？')
        .then(() => {
          this.planStatExaminationGroupModel = row
          planStatExaminationGroupService.cancelFinalSubmit(auth.getUserInfo().projectGuid, this.planStatExaminationGroupModel.planStatExaminationGroupGuid)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '取消最终批复成功！'
                })
                this.getData()
                this.$refs.submitFormDialog.close()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '取消最终批复失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleAdd () {
      let planStatExaminationGroupModel = new PlanStatExaminationGroupModel({
        tenderGuid: this.tenderGuid,
        examinationType: this.examinationType,
        year: this.year,
        month: this.month,
        date: this.showDate ? this.date : 1
      })
      planStatExaminationGroupService.add(planStatExaminationGroupModel)
        .then(res => {
          if (res.data.code === 1) {
            this.getData()
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    handleClick (row) {
      this.setPlanStatExaminationGuid(row.planStatExaminationGuid)
      this.setTenderGuid(row.tenderGuid)
      this.setTenderName(row.tenderName)
      this.setYear(row.year)
      this.setMonth(row.month)
      this.setDate(row.date)
      this.setExaminationCode(row.examinationCode)
      this.setApprovalUserGuid(row.approvalUserGuid)

      if (this.badaProjects.findIndex(item => auth.getUserInfo().projectGuid === item) > -1 && this.badaReports.findIndex(item => item === row.examinationType) > -1) {
        this.$router.push(`/${row.examinationType.toLowerCase()}Index-date`)
        return
      }

      if (this.importReports.indexOf(row.examinationType) !== -1 || this.descReports.indexOf(row.examinationType) !== -1) {
        this.handleImport(row)
      } else if (this.coverReports.indexOf(row.examinationType) !== -1) {
        this.$message({
          type: 'info',
          message: '请直接查看即可！'
        })
      } else {
        if (auth.getUserInfo().projectGuid === '82c864c0-362e-11ed-9b21-bf7727d33b03') {
          this.$router.push(`/final${row.examinationType}Index`)
        } else {
          this.$router.push(`/${row.examinationType}Index`)
        }
      }
    },
    handleImport (examinationModel) {
      this.examinationModel = examinationModel
      if (this.descReports.indexOf(this.examinationModel.examinationType) !== -1) {
        this.accept = '.pdf'
        this.tip = 'PDF'
      } else {
        this.accept = '.xls,.xlsx'
        this.tip = 'EXCEL'
      }

      this.$refs.importDialog.open()
    },
    doImport () {
      let isAnalysis = 1
      if (this.descReports.indexOf(this.examinationModel.examinationType) !== -1) {
        isAnalysis = 0
      }
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.examinationModel.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.uploadAction = `${config.reportHost}/UploadFile?date=${utility.formatTime(new Date(this.examinationModel.year, this.examinationModel.month - 1, this.examinationModel.date), 8)}&isanalysi=${isAnalysis}&examination=${this.examinationModel.planStatExaminationGuid}&userguid=${this.examinationModel.createUserGuid}&timestamp=${timestamp}&parameter=${token}&Category=0`

      this.uploadData = {
        date: utility.formatTime(new Date(this.examinationModel.year, this.examinationModel.month - 1, this.examinationModel.date), 8),
        isanalysi: isAnalysis,
        examination: this.examinationModel.planStatExaminationGuid,
        userguid: this.examinationModel.createUserGuid,
        timestamp: timestamp,
        parameter: token
      }

      this.$nextTick(() => {
        this.$refs.upload.submit()
      })
    },
    handleRemove () {
    },
    handleSuccess () {
      this.$message({
        type: 'success',
        message: '导入成功！'
      })
      this.$refs.importDialog.close()
    },
    handleError () {
      this.$message({
        type: 'error',
        message: '导入失败！'
      })
      this.$refs.importDialog.close()
    },
    getData () {
      planStatExaminationGroupService.list({}, { examinationType: this.examinationType, tenderGuid: this.tenderGuid })
        .then(res => {
          this.planStatExaminationGroups = res.data.data
          this.planStatExaminationGroups.forEach(item => {
            item.planStatExaminations = item.planStatExaminations.sort((a, b) => a.report.reportAlias <= b.report.reportAlias ? -1 : 1)
          })
          this.$message({
            type: 'success',
            message: '加载列表成功！'
          })
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败'
          })
        })
    },
    titleFilter (group) {
      let result = ''
      if (this.showYear) {
        result += group.year + '年'
      }
      if (this.showMonth) {
        result += group.month + '月'
      }
      if (this.showDate) {
        result += group.date + '日'
      }
      result += ' ' + group.approvalStatus + (!group.approvalUser ? '' : ' - ' + group.approvalUser.staffName)
      return result
    },
    getNextUsers (planStatExaminationGroupGuid) {
      planStatExaminationGroupService.getUsers(planStatExaminationGroupGuid)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            let data = res.data.data
            if (data.length) {
              data.forEach(flow => {
                flow.label = flow.stepName
                flow.value = flow.flowGuid
                if (flow.users && flow.users.length) {
                  flow.users.forEach(user => {
                    user.label = user.staffName
                    user.value = user.userGuid
                  })
                }
              })
              this.$refs.submitFormDialog.open()
              this.identityAndUsers = data
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    handleReportClick (examinationModel, format, inline = false) {
      if (this.descReports.indexOf(examinationModel.examinationType) !== -1) {
        let timestamp = utility.getCurrentTimestamp()
        let token = utility.getMd5(`${examinationModel.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

        let tempList = []
        axios.get(`${config.reportHost}/GetFileList`, {
          params: {
            examination: examinationModel.planStatExaminationGuid,
            userguid: examinationModel.createUserGuid,
            timestamp: timestamp,
            parameter: token
          }
        }).then(res => {
          if (res.data.Code === 1) {
            tempList = res.data.Msg.sort((a, b) => b.SortId - a.SortId)
            if (tempList.length) {
              window.open(`${this.reportPrefix + tempList[0].SavePath}`, '_blank')
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.Msg
            })
          }
        }).catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '获取附件失败，请重试！'
          })
        })
        return
      }
      let date = `${examinationModel.year}${utility.padStart(examinationModel.month, 2)}${utility.padStart(examinationModel.date, 2)}`
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.url = `${config.reportHost}/reportjhtj?format=${format}&inline=${inline}&date=${date}&tender=${this.tenderGuid}&report=${examinationModel.report.reportGuid}&timestamp=${timestamp}&parameter=${token}`

      axios.get(this.url)
        .then(res => {
          if (inline) {
            this.dialogTitle = examinationModel.report.reportAlias + ' ' + examinationModel.report.reportName
            this.$refs.myDialog.open()
          } else {
            window.open(this.url, '_blank')
          }
        })
        .catch(err => {
          console.log(err)
          if (inline) {
            this.dialogTitle = examinationModel.report.reportName + ' ' + examinationModel.report.reportAlias
            this.$refs.myDialog.open()
          } else {
            window.open(this.url, '_blank')
          }
        })
    },
    ...mapMutations('planStatExamination', {
      setPlanStatExaminationGuid: 'setPlanStatExaminationGuid',
      setTenderGuid: 'setTenderGuid',
      setTenderName: 'setTenderName',
      setYear: 'setYear',
      setMonth: 'setMonth',
      setDate: 'setDate',
      setExaminationCode: 'setExaminationCode',
      setApprovalUserGuid: 'setApprovalUserGuid'
    })
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.group-wrapper {
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  padding-top: 38px;
  margin-bottom: 50px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-gap: 3px 10px;

  .control {
    position: absolute;
    right: 50px;
    top: -15px;
    width: 250px;
    line-height: 30px;
    padding: 0 5px;
    background-color: white;
    text-align: center;
    font-size: 13px;
  }

  .year-title {
    position: absolute;
    left: 50px;
    top: -15px;
    width: 240px;
    line-height: 30px;
    padding: 0 5px;
    background-color: white;
    text-align: center;
    font-size: 13px;
  }

  .report-item {
    min-width: 380px;
    display: grid;
    grid-template-columns: 64px 1fr;
    margin: 0 18px 18px 0;
    margin-left: 0;
    margin-right: 15px;
    padding: 7px;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 0 5px #eee;
  }

  .bg-info {
    width: 64px;
    height: 64px;
    line-height: 64px;
    color: white;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
  }

  .report-item i {
    font-size: 30px;
  }

  .report-item:hover {
    color: rgb(30, 170, 192);
  }

  .buttons-wrapper {
    box-sizing: border-box;
    padding-left: 15px;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    position: relative;

    span {
      cursor: pointer;
    }
  }

  .btn {
    border-right: 1px solid #ddd;
  }

  .btn:last-child {
    border-right: 0;
  }

  .btn:hover {
    background-color: #ddd;
  }

  .btn i {
    font-size: 14px;
  }

  .icons {
    position: absolute;
    width: 150px;
    height: 32px;
    border-radius: 5px;
    line-height: 32px;
    box-sizing: border-box;
    background-color: rgb(244, 244, 244);
    border: 1px solid #ddd;
    margin-top: 6px;
    top: 24px;
    right: 0;
    cursor: pointer;
  }
}

.transfer {
  color: rgb(102, 178, 228);
}
</style>
